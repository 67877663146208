<template>
  <div>
    <v-card>
      <v-card-text>

           <v-row>
          <v-col cols="12" sm="4" md="4">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-select v-model="report_type" :items="report_types" item-text="name" item-value="id" label="Report Type"></v-select>
          </v-col>

        </v-row>

       




        <v-btn  color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>



      </v-card-text>
    </v-card>


      <v-row v-if="flag==1">
        <v-col  cols="12" sm="12" md="12">

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1260 style='width:944.75pt;border-collapse:collapse'>
     <tr>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
      <td width=23 style='width:17.25pt;padding:0in 0in 0in 0in;min-width: 6.09mm'></td>
      <td width=1227 style='width:920.35pt;padding:0in 0in 0in 0in;min-width: 324.64mm'></td>
      <td width=9 style='width:7.1pt;padding:0in 0in 0in 0in;min-width: 2.51mm'></td>
     </tr>
     <tr style='height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=23 valign=top style='width:17.25pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 6.09mm'></td>
      <td width=1237 colspan=2 valign=top style='width:927.5pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 327.16mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:.25in'>
        <td width=1229 valign=top style='width:921.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 325.17mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;color:black'>Court Manager</span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=23 valign=top style='width:17.25pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 6.09mm'></td>
      <td width=1227 valign=top style='width:920.35pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 324.64mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:.25in'>
        <td width=1227 valign=top style='width:920.35pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 324.64mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:10.0pt;color:black'>Active Court Proceedings as of
        2020-11-06</span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=9 valign=top style='width:7.1pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 2.51mm'></td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr>
    <td style='padding:0in 0in 0in 0in;min-width: 333.11mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 cols=1>
     <tr>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1260 style='width:944.75pt;border-collapse:collapse;min-width: 333.11mm'>
       <tr style='height:3.3pt'>
        <td width=1259 style='width:944.35pt;padding:0in 0in 0in 0in;
        height:3.3pt'></td>
       </tr>
       <tr>
        <td width=1259 valign=top style='width:944.35pt;padding:0in 0in 0in 0in;
        min-width: 333.11mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1259 style='width:944.35pt;border-collapse:collapse;min-width: 333.11mm'
         cols=15>
         <tr>
          <td width=99 style='width:74.25pt;padding:0in 0in 0in 0in;min-width: 333.11mm'></td>
          <td width=158 style='width:118.5pt;padding:0in 0in 0in 0in;
          min-width: 41.80mm'></td>
          <td width=71 style='width:53.25pt;padding:0in 0in 0in 0in;min-width: 18.79mm'></td>
          <td width=52 style='width:39.0pt;padding:0in 0in 0in 0in;min-width: 13.76mm'></td>
          <td width=44 style='width:33.0pt;padding:0in 0in 0in 0in;min-width: 11.64mm'></td>
          <td width=79 style='width:59.25pt;padding:0in 0in 0in 0in;min-width: 20.90mm'></td>
          <td width=81 style='width:60.75pt;padding:0in 0in 0in 0in;min-width: 21.43mm'></td>
          <td width=67 style='width:50.25pt;padding:0in 0in 0in 0in;min-width: 17.73mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
         </tr>
         <tr style='height:.25in'>
          <td width=99 valign=top style='width:74.25pt;border:solid silver 1.0pt;
          border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=158 valign=top style='width:118.5pt;border-top:solid silver 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=96 colspan=2 valign=top style='width:1.0in;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Days</span></i></p>
          </td>
          <td width=758 colspan=9 valign=top style='width:568.55pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Court Details</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:15.1pt'>
          <td width=99 valign=top style='width:74.25pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request</span></i></p>
          </td>
          <td width=158 valign=top style='width:118.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request Summary</span></i></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Applicant</span></i></p>
          </td>
          <td width=52 valign=top style='width:39.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Allowed</span></i></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Taken</span></i></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date Application
          Filed</span></i></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date Advised</span></i></p>
          </td>
          <td width=67 valign=top style='width:50.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Court File Number</span></i></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Contact File Number</span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Section 41</span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Section 42</span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Section 43</span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Court Comment</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Date of Decision</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Request Status</span></i></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1260 style='width:944.75pt;border-collapse:collapse' cols=5>
     <tr style='height:11.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:11.25pt'></td>
      <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 0.53mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
      <td width=873 style='width:654.85pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 230.98mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
     </tr>
     <tr style='height:.75pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.75pt'></td>
      <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
      height:.75pt;min-width: 0.53mm'></td>
      <td width=192 rowspan=2 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:.75pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;color:black'>2020-11-06
        3:35:07 PM</span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=1065 colspan=2 valign=top style='width:798.85pt;padding:0in 0in 0in 0in;
      height:.75pt;min-width: 281.78mm'></td>
     </tr>
     <tr style='height:8.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:8.25pt'></td>
      <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
      height:8.25pt;min-width: 0.53mm'></td>
      <td width=873 valign=top style='width:654.85pt;padding:0in 0in 0in 0in;
      height:8.25pt;min-width: 230.98mm'></td>
      <td width=192 rowspan=2 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:8.25pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;color:black'>Page 1 / 1</span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='height:.75pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:.75pt'></td>
      <td width=1067 colspan=3 rowspan=2 style='width:800.35pt;padding:0in 0in 0in 0in;
      height:.75pt;min-width: 282.31mm'></td>
     </tr>
     <tr style='height:9.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:9.0pt'></td>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;color:black'>Axcess-1</span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

        
        </v-col>
        </v-row>

    <div v-else>
      <v-progress-circular
          v-if="flag==1"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>


<script>

import DatePicker from "../components/DatePicker";

export default {

  components: {
 DatePicker,

  },
  data(){
    return {
      flag:0,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      report_types:[
        { id:'0',name:"Active Complaints as of 2020-11-03"},
        { id:'1',name:"Completed Complaints from 2015-01-01 to 2020-11-03"},
        { id:'2',name:"Recieved Complaints from 2015-01-01 to 2020-11-03"}


      ],
      report_type:"Active Complaints as of 2020-11-03",
    

   
  
    };
  },



  methods:{
     
    selected(){

    },


  


    generateReport(){
      this.flag = 1;
    },
    closeReport(){
      this.flag = 0;
    }
  },
   computed: {

   },
 
    mounted () {
 
    },
  
};

</script>